<template>
  <div class="flex flex-col gap-8">
    <span class="font-medium">Survey Preview</span>
    <div class="flex flex-col gap-10 bg-white py-12 px-12 shadow-lg rounded-lg">
      <div
        v-for="(item, questionIndex) in questions"
        :key="questionIndex"
        class="flex flex-col gap-4"
      >
        <span class="text-textGray">{{ item.question }}</span>
        <div class="grid grid-cols-5 gap-4">
          <div v-for="(option, optionIndex) in options" :key="optionIndex">
            <label
              class="flex cursor-pointer text-left rounded-md border border-textGray-600 bg-white p-4 text-gray-900 hover:border-gray-200 checked:border-teal checked:bg-teal checked:text-white"
            >
              <p class="text-sm text-textGray">{{ option }}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!previewPage">
      <div class="flex flex-col gap-8 pointer-events-none opacity-40">
        <h1 class="font-semibold text-xl">Your feedback here</h1>
        <div>
          <label
            class="block text-gray-500 text-xs font-bold mb-2"
            for="question"
          >
            What was the best highlight of your visit (If you happen to know who
            helped it make better)?
          </label>
          <input
            class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            id="question"
            type="text"
            placeholder="Type here"
          />
          <p class="text-gray-600 text-xs">
            Type your feedback here between 1 to 200 characters
          </p>
        </div>
        <div>
          <label
            class="block text-gray-500 text-xs font-bold mb-2"
            for="question"
          >
            Is there anything we can do to improve?
          </label>
          <input
            class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            id="question"
            type="text"
            placeholder="Type here"
          />
          <p class="text-gray-600 text-xs">
            Type your feedback here between 1 to 200 characters
          </p>
        </div>
      </div>
      <div class="w-100 flex justify-between">
        <button
          class="bg-transparent border-teal border w-24 h-12 text-teal rounded-md hover:bg-teal hover:text-white"
          @click="$router.go(-1)"
        >
          Cancel
        </button>
        <button
          class="bg-teal float-right h-12 w-56 text-white rounded-md"
          @click="generateLink"
        >
          Save and Generate links
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import Swal from "sweetalert2";
import { useRouter, useRoute } from "vue-router";
import icon from "@/assets/icons/success.svg";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

const surveyData = ref(null);
surveyData.value = JSON.parse(localStorage.getItem("survey-questions"));

const previewPage = ref(false);

const surveyQuestions = computed(() => {
  return store.getters["patientSurveys/getQuestionsAndAnswers"];
});

const options = ref([
  "Strongly Disagree",
  "Disagree",
  "Neutral",
  "Agree",
  "Strongly Agree",
]);

const questions = ref([]);

const fetchQuestions = async () => {
  if (route.path.includes("/hq-survey-entries")) {
    await store.dispatch("patientSurveys/fetchQuestions");
    previewPage.value = true;
    questions.value = surveyQuestions.value.map((question) => ({
      question: question.question,
    }));
  } else {
    questions.value = surveyData.value.questions;
  }
};
const generateLink = async () => {
  await store
    .dispatch("patientSurveys/createPatientSurvey", {
      questions: surveyData.value.questions,
    })
    .then((response) => {
      Swal.fire({
        showConfirmButton: false,
        padding: "40px",
        timer: 3000,
        width: "40%",
        background: `rgba(225, 249, 240, 1)`,
        timerProgressBar: false,
        iconHtml: `<img src="${icon}" />`,
        customClass: {
          title: "custom-title",
          popup: "border-radius",
          content: "custom-text",
          icon: "icon-border",
        },
        didClose: () => {
          router.push({
            name: "ma-survey-entries",
            params: { id: response.id },
          });
        },
        title: "Your Links are Successfully Generated",
      });
    });
};

onMounted(fetchQuestions);
</script>
